import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => { return <div><Link to={edge.node.frontmatter.slug}>{edge.node.frontmatter.title}</Link> - {edge.node.frontmatter.date}</div> });

  return (
    <Layout>
      <SEO title="Home" />
      <p>Hey, I'm Jon, a full stack software engineer currently working 
        at <a href="https://www.filevine.com/">Filevine</a> on a team building addons for Gmail and Outlook.</p>
      <p>I work on backend, platform and API's as well as front end code. I like Typescript and going "serverless" when I can.</p>
      <p>I also like crypto, synthesizers and of course, IPAs</p>
      <p>You can find me on:</p>
        <li>Twitter: <a href="https://twitter.com/jonmoshier">@jonmoshier</a></li>
        <li>Gitlab: <a href="https://gitlab.com/jonmoshier">@jonmoshier</a></li>
        <li>LinkedIn: <a href="https://www.linkedin.com/in/jmoshier">Jon Moshier</a></li>
        <li>Email: jonmoshier@gmail.com</li>
    <br />
    <h4>Writings</h4>
      {Posts}
    </Layout>
  );
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`